<template>
  <div>
    <section id="title" class="mb16">
      <h1 class="fw700 fs28 dib mr10">Query</h1>
    </section>

    <section id="panel">
      <span class="fs14">SKU</span>
      <el-input
        style="width: 200px; margin-left: 10px"
        clearable
        v-model="skuInput"
        placeholder="search sku"
        size="small"
      />
      <template v-if="isAdmin">
        <span class="ml20">stockx rate</span>
        <el-select
          v-model="stockxRate"
          class="pl10"
          size="small"
          placeholder="stockx rate"
        >
          <el-option
            v-for="rate in stockxRates"
            :key="rate"
            :label="rate"
            :value="rate"
          />
        </el-select>
      </template>
      <span class="ml20">sizes</span>
      <el-select
        v-model="sizesSelected"
        class="pl10"
        size="small"
        placeholder="sizes"
        multiple
        filterable
        clearable
      >
        <el-option
          v-for="size in sizes"
          :key="size"
          :label="size"
          :value="size"
        />
      </el-select>

      <el-button
        @click="search"
        :loading="loading"
        class="ml10"
        size="small"
        type="primary"
      >
        search
      </el-button>

      <el-upload
        v-if="isAdmin"
        class="dib ml10"
        action=""
        :show-file-list="false"
        :auto-upload="false"
        :on-change="fileHandler"
      >
        <el-button size="small" type="primary" :loading="BatchLoading">
          Batch search
        </el-button>
      </el-upload>

      <el-button
        v-if="isAdmin"
        @click="downloadTemplate"
        class="ml10"
        size="small"
        type="primary"
      >
        download template
      </el-button>

      <el-button
        v-if="isAdmin"
        @click="downloadResult"
        class="ml20"
        size="small"
        type="primary"
      >
        download result
      </el-button>
    </section>

    <section id="table" class="mt24">
      <el-table
        :data="tableData"
        :summary-method="summaryTable"
        size="small"
        empty-text="null"
        stripe
        border
        show-summary
        style="width: 100%"
      >
        <el-table-column label="product-picture">
          <template #default="{ row }">
            <img width="60" :src="row.imgUrl" />
          </template>
        </el-table-column>

        <el-table-column label="product-name">
          <template #default="{ row }">
            <h3 class="fw700">{{ row.name }}</h3>
          </template>
        </el-table-column>

        <el-table-column prop="size" label="size" width="100" />
        <el-table-column prop="sku" label="sku" width="100" />

        <el-table-column label="amount" width="180">
          <template slot-scope="scope">
            <div>
              <el-input-number
                v-model="scope.row.amount"
                controls-position="right"
                :min="0"
                :max="100000000"
                size="mini"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if="isAdmin" label="StockX" prop="stockx">
          <template #default="{ row }">
            <el-input v-model="row.stockx" size="mini" />
          </template>
        </el-table-column>
        <el-table-column v-if="isAdmin" label="stockx rate">
          <template #default="{ row }">
            <el-input v-model="row.stockxRate" size="mini" />
          </template>
        </el-table-column>

        <el-table-column
          v-if="isAdmin"
          prop="stockxFinal"
          label="StockX revenue"
        >
          <template slot-scope="scope">
            <div>
              {{ (scope.row.stockx * scope.row.stockxRate).toFixed(2) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="goat" label="GOAT">
          <template slot-scope="scope">
            <div>{{ scope.row.goat }} (instant)</div>
            <div>{{ scope.row.goatSTV }} (stv)</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="goatDropship"
          label="GOAT lowest bid"
          min-width="120"
        >
          <template slot-scope="scope">
            <div class="main-color fw700">
              {{ scope.row.goatDropship }} (instant)
            </div>
            <div class="main-color fw700">{{ scope.row.goatSTV }} (stv)</div>
          </template>
        </el-table-column>

        <el-table-column prop="goatFinal" label="GOAT revenue" min-width="100">
          <template slot-scope="scope">
            <div class="warning-color fw700">
              <span class="vam"> {{ scope.row.goatFinal }} (instant) </span>
              <revenue-details
                :data="[
                  {
                    feeType: 'sold price',
                    feePrice: scope.row.goatDropship,
                  },
                  {
                    feeType: 'commission fee',
                    feePrice: userInfo.commissionRate + '%',
                  },
                  { feeType: 'cashout fee', feePrice: 2.9 + '%' },
                  {
                    feeType: 'tech service fee',
                    feePrice: userInfo.techServicePrice,
                  },
                  {
                    feeType: 'shipping fee',
                    feePrice: userInfo.transportServicePrice,
                  },
                  {
                    feeType: 'GOAT process fee',
                    feePrice: userInfo.processServicePrice,
                  },
                ]"
              />
            </div>
            <div class="warning-color fw700">
              <span class="vam"> {{ scope.row.goatSTVFinal }} (stv) </span>
              <revenue-details
                :data="[
                  {
                    feeType: 'sold price',
                    feePrice: scope.row.goatSTV,
                  },
                  {
                    feeType: 'commission fee',
                    feePrice: userInfo.commissionRate + '%',
                  },
                  { feeType: 'cashout fee', feePrice: 2.9 + '%' },
                  {
                    feeType: 'tech service fee',
                    feePrice: userInfo.techServicePrice,
                  },
                  {
                    feeType: 'shipping fee',
                    feePrice: userInfo.transportServicePrice,
                  },
                  {
                    feeType: 'GOAT process fee',
                    feePrice: userInfo.processServicePrice,
                  },
                ]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if="isAdmin" prop="profit" label="single profit">
          <template slot-scope="scope">
            <div
              :class="{
                'success-color':
                  Number(scope.row.goatFinal) - scope.row.stockx * stockxRate >
                  50,
                'error-color':
                  Number(scope.row.goatFinal) - scope.row.stockx * stockxRate <
                  0,
              }"
            >
              {{
                (
                  Number(scope.row.goatFinal) -
                  scope.row.stockx * scope.row.stockxRate
                ).toFixed(2)
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if="isAdmin" prop="profit" label="sum of profit">
          <template slot-scope="scope">
            <div>
              {{
                (
                  (Number(scope.row.goatFinal) -
                    scope.row.stockx * scope.row.stockxRate) *
                  scope.row.amount
                ).toFixed(2)
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </section>
  </div>
</template>

<script>
import Papa from 'papaparse'

import constants from '../../assets/js/constants'
import { downloadXLSX } from '../../assets/js/tools'
import RevenueDetails from '@/components/RevenueDetails.vue'

const { sizes, stockxRates } = constants

export default {
  name: 'Query',
  data() {
    return {
      skuInput: '',
      stockxRate: 0.9,
      goatRate: 0.898,
      sizesSelected: [],
      loading: false,
      BatchLoading: false,
      tableData: [],
      sizes,
      stockxRates,
      skuMap: new Map(),
      userInfo: {},
    }
  },
  components: {
    'revenue-details': RevenueDetails,
  },
  computed: {
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('user'))
      return user.isAdmin
    },
  },
  async created() {
    const userInfo = await this.fetchUserInfo()
    const commissionRate = userInfo.commissionRate
    const commission = (100 - commissionRate) / 100
    const cashoutRate = 0.971
    const rate = Number((commission * cashoutRate).toFixed(3))
    this.userInfo = userInfo
    this.goatRate = rate
  },
  methods: {
    async fetchUserInfo() {
      const { data: userInfo } = await this.$fetch({
        url: '/api/user/info',
        autoShowErrorMessage: true,
      })
      return userInfo
    },

    fileHandler(file) {
      Papa.parse(file.raw, {
        complete: ({ data: fileDataArr }) => {
          this.skuInput = ''
          this.sizesSelected = []
          const skuList = []
          this.skuMap.clear()

          for (let v of fileDataArr) {
            if (!v.sku) continue

            const sku = v.sku.trim()
            const skuData = this.skuMap.get(sku)
            skuList.push(sku)

            if (skuData) {
              skuData.sizes.push(Number(v.size))
              skuData.stockxRates.push(v['stockx rate'])
              skuData.amount.push(v.amount)
            } else {
              this.skuMap.set(sku, {
                sizes: [Number(v.size)],
                stockxRates: [v['stockx rate']],
                amount: [v.amount],
              })
            }
          }

          this.batchQuery([...new Set(skuList)])
        },
        header: true,
      })
    },

    async batchQuery(skuList) {
      try {
        this.BatchLoading = true
        const { data: skuDataList } = await this.$fetch({
          url: '/api/batchQuery',
          method: 'POST',
          data: skuList,
          autoShowErrorMessage: true,
        })
        this.tableData = []

        skuDataList.forEach((i) => {
          this.dealTableData(i.sku, i.stockxList, i.template, i.offers)
        })
        this.BatchLoading = false
      } finally {
        this.BatchLoading = false
      }
    },

    downloadTemplate() {
      const head = ['sku', 'size', 'stockx rate', 'amount']
      downloadXLSX([head], 'fileTemplate', '.csv')
    },

    downloadResult() {
      const head = [
        'name',
        'sku',
        'size',
        'amount',
        'stockx',
        'StockX revenue',
        'GOAT',
        'GOAT instant lowest bid',
        'GOAT instant revenue',
        'single profit',
        'sum of profit',
      ]
      const temp = this.tableData.map((i) => {
        return [
          i.name,
          i.sku,
          i.size,
          i.amount,
          i.stockx,
          (i.stockx * i.stockxRate).toFixed(2),
          i.goat,
          i.goatDropship,
          i.goatFinal,
          (Number(i.goatFinal) - i.stockx * i.stockxRate).toFixed(2),
          ((Number(i.goatFinal) - i.stockx * i.stockxRate) * i.amount).toFixed(
            2
          ),
        ]
      })
      downloadXLSX([head, ...temp], 'result', '.csv')
    },

    async fetchStockxPrices() {
      const res = await this.$fetch({
        url: '/api/stockxquery',
        params: { sku: this.skuInput.trim() },
        autoShowErrorMessage: true,
      })
      return res.data
    },

    async fetchGoatPrices() {
      const res = await this.$fetch({
        url: '/api/goatquery',
        params: { sku: this.skuInput.trim() },
        autoShowErrorMessage: true,
      })
      return res.data
    },

    async combineFetch() {
      const result = []
      try {
        if (this.isAdmin) {
          const data = await this.fetchStockxPrices()
          result[0] = data
        } else {
          result[0] = []
        }
      } catch (e) {
        result[0] = []
      }
      const data = await this.fetchGoatPrices()
      result[1] = data
      return result
    },

    async search() {
      const curSku = this.skuInput.trim()
      if (!curSku) {
        return this.$message.error('sku is required')
      }

      if (!this.sizesSelected.length) {
        return this.$message.error('sizes is required')
      }

      this.skuMap.clear()
      this.skuMap.set(curSku, {
        sizes: [...this.sizesSelected], // 深克隆
        stockxRates: new Array(this.sizesSelected.length).fill(this.stockxRate), // 保证一个size对应一个stockxRate, 即便stockxRate相同
        amount: new Array(this.sizesSelected.length).fill(1),
      })

      try {
        this.loading = true
        const combineData = await this.combineFetch()
        this.tableData = []
        this.dealTableData(
          curSku,
          combineData[0],
          combineData[1].template,
          combineData[1].offers
        )
        this.loading = false
      } catch (err) {
        console.log(err)
        this.$message.error('search error')
        this.loading = false
      }
    },

    dealTableData(curSku, stockxList, template, goatList) {
      const sizeDict = {}
      stockxList.forEach((item) => {
        item.size = item.size.replace(/[YyWwCc]/g, '')
        sizeDict[item.size] = { stockx: 0, goat: 0 }
      })
      goatList.forEach((item) => {
        if (!sizeDict[item.size]) {
          sizeDict[item.size] = { stockx: 0, goat: 0 }
        }
      })
      stockxList.forEach((item) => {
        sizeDict[item.size].stockx = item.price
      })
      goatList.forEach((item) => {
        sizeDict[item.size].goat =
          (item.instantShipLowestPriceCents.amount - 700) / 100
        sizeDict[item.size].goatSTV = (item.lowestPriceCents.amount - 700) / 100
      })

      const prices = Object.entries(sizeDict)
      prices.sort((a, b) => {
        return Number(a[0]) - Number(b[0])
      })

      const result = prices
        .map((price) => {
          const goatFinal = (
            (price[1].goat / 1.08) * this.goatRate -
            this.userInfo.techServicePrice -
            this.userInfo.transportServicePrice -
            this.userInfo.processServicePrice
          ).toFixed(2)
          const goatSTVFinal = (
            price[1].goatSTV * this.goatRate -
            this.userInfo.techServicePrice -
            this.userInfo.transportServicePrice -
            this.userInfo.processServicePrice
          ).toFixed(2)

          return {
            size: price[0],
            stockx: price[1].stockx,
            goat: price[1].goat,
            goatSTV: price[1].goatSTV,
            goatDropship: (Number(price[1].goat) / 1.08).toFixed(2),
            goatFinal,
            goatSTVFinal,
            amount: 1,
            imgUrl: template.gridPictureUrl,
            name: template.name,
            sku: curSku,
          }
        })
        .filter((price) => {
          return this.skuMap.get(curSku).sizes.includes(Number(price.size))
        })

      const skuData = this.skuMap.get(curSku)

      skuData.sizes.forEach((size, index) => {
        const price = result.find((i) => Number(i.size) == size)
        this.tableData.push({
          ...price,
          stockxRate: skuData.stockxRates[index],
          amount: skuData.amount[index],
        })
      })
    },

    summaryTable() {
      const amountSum = this.tableData.reduce(
        (sum, item) => sum + item.amount,
        0
      )
      const stockxFinalSum = this.tableData.reduce((sum, item) => {
        return sum + item.stockx * this.stockxRate * item.amount
      }, 0)
      const goatFinalSum = this.tableData.reduce((sum, item) => {
        return sum + (item.goat / 1.08) * this.goatRate * item.amount
      }, 0)
      const profitSum = this.tableData.reduce((sum, item) => {
        return (
          sum +
          ((item.goat / 1.08) * this.goatRate - item.stockx * item.stockxRate) *
            item.amount
        )
      }, 0)
      const profitRate = ((profitSum / stockxFinalSum) * 100).toFixed(2) + '%'
      if (this.isAdmin) {
        return [
          'sum',
          '',
          '',
          '',
          amountSum,
          '',
          '',
          stockxFinalSum.toFixed(2),
          '',
          '',
          goatFinalSum.toFixed(2),
          '',
          `${profitSum.toFixed(2)}(${profitRate})`,
        ]
      } else {
        return ['sum', '', '', '', amountSum, '', '', '']
      }
    },
  },
}
</script>
